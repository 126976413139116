export class ADUser {
  id: string;
  displayName: string;
  email: string;
  avatar: string;
  timeZone: string;
  constructor() {
    this.id = '';
    this.displayName = '';
    this.email = '';
    this.avatar = '';
    this.timeZone = '';
  }
}
