import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, from, Observable, switchMap } from 'rxjs';
import { ADUser } from '../shared/models/franchise/ad_user';
import { MsalConfig } from './msal.config';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Client, ResponseType } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { graphConfig } from './graph.config';
import { SilentRequest, PopupRequest, AccountInfo, InteractionRequiredAuthError, BrowserAuthError } from '@azure/msal-browser';
import { SSOAuthenticationResult } from '../shared/models/franchise/sso-authenticationResult';
import { Identifier } from '../shared/models/storage.models';

export enum SSOLoginStatus {
  Login = 1,
  Logout = 2,
  Cancelled = 3,
}

@Injectable({ providedIn: 'root' })
export class GraphService {
  private graphClient!: Client;
  private authenticated$: BehaviorSubject<SSOLoginStatus>;
  private ad_user?: ADUser;
  private renewIdTokenRequest = {
    scopes: MsalConfig.msGraphScopes,
    account: MsalConfig.account,
  };
  constructor(
    private http: HttpClient,
    private msalService: MsalService
  ) {
    this.authenticated$ = new BehaviorSubject<SSOLoginStatus>(SSOLoginStatus.Logout);
    this.initGraphClient();
  }

  private initGraphClient(): void {
    this.graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        const token = this.getAccessToken();
        if (token) {
          done(null, token);
        } else {
          done('Could not get an access token', null);
        }
      },
    });
  }
  private async getUser(): Promise<ADUser> {
    const user = new ADUser();
    const authenticated = this.authenticated$.toPromise().then();
    if (!authenticated) {
      return null!;
    }
    if (!this.graphClient) {
      this.initGraphClient();
    }
    try {
      // Get the user from Graph (GET /me)
      const graphUser: MicrosoftGraph.User = await this.graphClient
        .api(graphConfig.graphMeEndpoint)
        .get();
      const response = await this.graphClient
        .api(graphConfig.graphPhotoEndpoint)
        .version('beta')
        .responseType(ResponseType.RAW)
        .get();
      if (!response.ok) {
        console.log('User photo not found');
      }
      const url = window.URL || window.webkitURL;
      const blob = await response.blob();
      user.displayName = graphUser.displayName || '';
      // Prefer the mail property, but fall back to userPrincipalName
      user.email = graphUser.mail || graphUser.userPrincipalName || '';
      user.timeZone = graphUser?.mailboxSettings?.timeZone || '';
      user.id = graphUser.id || '';
      if (blob) {
        user.avatar = url.createObjectURL(blob);
      } else {
        // Use default avatar
        user.avatar = '/assets/no-profile-photo.png';
      }
    } catch (error) {
      throw new Error(`Could not get user, ${JSON.stringify(error, null, 2)}`);
    }
    return user;
  }
  private getAccessToken(): string {
    if (
      localStorage.getItem('msal.idtoken') !== undefined &&
      localStorage.getItem('msal.idtoken') != null
    ) {
      return localStorage.getItem('msal.idtoken') || '';
    }
    return '';
  }
  private fetchAccessTokenByScopeMSAL(
    request: SilentRequest | PopupRequest
  ): Observable<SSOAuthenticationResult> {
    let accountObject = MsalConfig.account;
    if (!accountObject) {
      const accounts: AccountInfo[] =
        this.msalService.instance.getAllAccounts();
      if (accounts && accounts.length > 0) {
        accountObject = accounts[0] as any;
        request.account = accountObject as any;
        MsalConfig.account = accountObject;
      }
    }
    if (accountObject) {
      console.log('[AuthService.init] Got valid accountObj and tokenResponse');
      return this.msalService.acquireTokenSilent(request).pipe(
        catchError((error) => {
          console.log(error);
          return this.msalService.acquireTokenPopup(request);
        })
      );
    } else {
      console.log(
        '[AuthService.init] No accountObject or tokenResponse present. User must now login.'
      );
      return this.msalService.acquireTokenPopup(request);
    }
  }
  // private getMsalClinicMasterToken(authToken: string): Observable<AuthResult> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': `Bearer ${authToken}`,
  //     }),
  //   };
  //   let url: string = APIRoutesConfig.ssoapiUrl;
  //   if (!url.endsWith('/')) {
  //     url = url + '/';
  //   }
  //   url += MsalConfig.authSchema + '/co';
  //   return this.http.get<AuthResult>(url, httpOptions);
  // }
  public get ssoAuthenticated$() {
    return this.authenticated$;
  }
  public acquireToken(): void {
    if (!MsalConfig.isEnabled) {
      return;
    }
    this.fetchAccessTokenByScopeMSAL(this.renewIdTokenRequest as any)
      .pipe(
        switchMap((token) => {
          localStorage.setItem('msal.idtoken', token.accessToken);
          return this.fetchAccessTokenByScopeMSAL({
            scopes: MsalConfig.cmApiRequest,
            account: MsalConfig.account as any,
          });
        })
      )
      .pipe(
        switchMap((authRes) => {
          localStorage.setItem('msal.accToken', authRes.accessToken);
          //return this.getMsalClinicMasterToken(authRes.accessToken);
          return from(this.getUser());
        })
      )
      // .pipe(
      //   switchMap((token) => {
      //     localStorage.setItem(Identifier.TYPE_FRANCHISE_TOKEN, JSON.stringify(token));
      //     return from(this.getUser());
      //   })
      // )
      .subscribe({
        next: (adUser: ADUser) => {
          this.ad_user = adUser;
          if (this.ad_user) {
            localStorage.setItem(Identifier.AD_USER, JSON.stringify(this.ad_user));
          }
          this.authenticated$.next(SSOLoginStatus.Login);
        },
        error: (error) => {
          console.log(error);
          if (error instanceof BrowserAuthError) {
            this.authenticated$.next(SSOLoginStatus.Cancelled);
          }

          if (error instanceof InteractionRequiredAuthError) {
            this.msalService.acquireTokenPopup(
              this.renewIdTokenRequest as any
            );
          }
        },
      });
  }
}
