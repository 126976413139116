import { Injectable } from '@angular/core';
import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of, EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  ClinicGroupFranchiseDto,
  ClinicGroupFranchiseSsoinfoDto,
  StackDto,
  TokenDto,
} from '../shared/models/franchise/franchise.model';

import { HelpersService } from '../shared/utils/helpers/helpers.service';
import { authenticateUser, initUser, unauthenticateUser } from '../store/user/user.actions';
import { selectUser } from '../store/user/user.selectors';
import { environment } from '../../environments/environment';
import { Identifier } from '../shared/models/storage.models';
import { MsalConfig } from './msal.config';
import { APIRoutesConfig } from '../api-routes.config';

@Injectable({ providedIn: 'root' })
export class InitialStartupService {
  private tokenExpirationTimer: any;
  hardcodedWebUrl = environment.stack;
  private http: HttpClient;
  public isSSOLogin = false;

  constructor(
    private readonly httpHandler: HttpBackend,
    private router: Router,
    private helper: HelpersService,
    private store: Store<any>
  ) {
    this.http = new HttpClient(httpHandler);
  }

  autoLogin(): Observable<TokenDto | null> {
    this.store.dispatch(initUser());
    return this.store.select(selectUser).pipe(
      switchMap((user) => {
        if (this.helper.userIsAuthenticated(user)) {
          this.autoLogout(this.helper.getExpirationDuration(user));
          return EMPTY; // no need to return anything
        }
        return of({}); // return empty object to continue subscription
      })
    );
  }

  logout() {
    this.clearTimer();
    this.store.dispatch(unauthenticateUser());
    this.router.navigate(['/auth']);
  }

  clearTimer() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    this.clearTimer();
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }
  handleAuthentication(tokenDto: TokenDto) {
    this.store.dispatch(authenticateUser(tokenDto));
    this.autoLogout(this.helper.getExpirationDuration(tokenDto));
  }
  private getDomainName(): string {
    const domains = location.hostname.split('.');
    if (domains[0] === '127') {
      return this.hardcodedWebUrl;
    }
    if (domains && domains.length > 1) {
      if (domains[0] === 'dev10') {
        return this.hardcodedWebUrl;
      }
      return window.location.host;
    } else {
      return this.hardcodedWebUrl;
    }
  }

  public initApplication() {
    return new Promise<void>((resolve, reject) => {
      let subDomain = this.getDomainName();
      const headers = new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Access-Control-Allow-Origin', '*')
        .append('Accept-Language', 'en-CA');

      let params = new HttpParams();
      params = params.append('portalUrl', subDomain);
      const url = `${APIRoutesConfig.coApiUrl}centraloffice/token/portalUrl`;
      return this.http
        .get<StackDto>(url, { observe: 'response', params, headers })
        .pipe(map((response) => response.body))
        .pipe(
          switchMap((backendConfig) => {
            if (!backendConfig) {
              return of(null);
            }

            this.initBackendConfigs(backendConfig);

            const headers = new HttpHeaders()
              .append('Content-Type', 'application/json')
              .append('Access-Control-Allow-Origin', '*')
              .append('Accept-Language', 'en-CA')
              .append(
                'ClinicGroupFranchiseId',
                backendConfig.clinicGroupFranchiseId.toString() || ''
              );
            const url = `${APIRoutesConfig.coApiUrl}clinicgroupfranchises/${backendConfig?.clinicGroupFranchiseId}`;
            return this.http
              .get<ClinicGroupFranchiseDto>(url, { observe: 'response', headers })
              .pipe(map((response) => response.body));
          })
        )
        .subscribe((franchise) => {
          if (franchise) {
            localStorage.setItem(
              Identifier.TYPE_FRANCHISE_ID,
              franchise.clinicGroupFranchiseId.toString()
            );
            localStorage.setItem(
              Identifier.TYPE_OTHER_SUPPORTED_LANGUAGES,
              JSON.stringify(franchise.otherSupportedLanguages)
            );

            const ssoInfo = franchise.ssoInfo?.find(
              (s) => s.sysSsotypeId === 3
            ); //for web=3

            if (ssoInfo) {
              this.initSSOConfigs(ssoInfo);
            } else {
              this.autoLogin();
            }
            resolve();
          }
        });
    });
  }

  private initSSOConfigs(ssoInfo: ClinicGroupFranchiseSsoinfoDto): void {
    this.isSSOLogin = ssoInfo && ssoInfo.sysSsotypeId === 3; //web
    const hostName =
      window.location.hostname === 'localhost'
        ? 'localhost:4200'
        : window.location.hostname;
    MsalConfig.isEnabled = true;
    MsalConfig.redirectUri = 'https://' + hostName;
    MsalConfig.clientId = ssoInfo.clientId;
    MsalConfig.cmApiRequest = [`api://${ssoInfo.apiClientId}/access_as_user`];
    MsalConfig.authority = `${ssoInfo.providerUri}${ssoInfo.tenantId}`;
    MsalConfig.authSchema = ssoInfo.authSchema;
    localStorage.setItem(
      Identifier.TYPE_IS_SSO_LOGIN,
      this.isSSOLogin.toString()
    );
  }

  private initBackendConfigs(beModel: StackDto): void {
    if (beModel) {
      sessionStorage.setItem(Identifier.TYPE_COAPI_URL, beModel.centralOfficeApiUrl);
      sessionStorage.setItem(Identifier.TYPE_CMAPI_URL, beModel.monitoringWebApiBaseUri);
      sessionStorage.setItem(Identifier.TYPE_SETTINGS_URL, beModel.clinicMasterSettingsUrl);
      sessionStorage.setItem(Identifier.TYPE_FILEAPI_URL, beModel.fileApiUrl);
      sessionStorage.setItem(Identifier.TYPE_REPORTAPI_URL, beModel.reportApiUrl);
      sessionStorage.setItem(Identifier.TYPE_SIGNALRAPI_URL, beModel.signalRwebApiBaseUri);
      sessionStorage.setItem(Identifier.TYPE_SSOAPI_URL, beModel.ssoapiUrl);
      sessionStorage.setItem(Identifier.TYPE_GRAPTHAPI_URL, beModel.addaGraphApiUrl);
      sessionStorage.setItem(Identifier.TYPE_POWERBIAPI_URL, beModel.powerBiBaseUrl);
      sessionStorage.setItem(Identifier.TYPE_FORMIO_PROJECT_URL, beModel.formioProjectUrl);
    }
  }
}
