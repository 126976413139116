import { AccountInfo } from '@azure/msal-browser';

export const MsalConfig = {
  isEnabled: false,
  redirectUri: 'https://' + window.location.hostname,
  authority: '',
  clientId: '',
  account: null,
  msGraphScopes: ['user.read', 'profile', 'openid', 'offline_access'],
  loginRequest: {
    scopes: ['openid', 'profile', 'User.Read', 'offline_access'],
  },
  tokenRequest: {
    scopes: ['User.Read'],
  },
  authSchema: '',
  cmApiRequest: [''],
};
