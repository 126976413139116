export const graphConfig = {
  graphMeEndpoint: '/me',
  graphCalendarViewEndpoint: 'me/calendarview',
  graphEventsEndpoint: 'me/events',
  graphPhotoEndpoint: '/me/photo/$value',
  graphMailEndpoint: '/me/messages',
  graphCalendarsEndpoint: '/me/calendars',
  graphCalendarEndpoint: '/me/calendars',
  graphEventEndpoint: '/me/events/'
};
